<template>
	<div class="layout-profile">
		<button class="p-link layout-profile-link text-center" @click="onClick">
			<span class="username"><strong>{{ nome }}</strong><span v-if="$root.distribuidor != null"><br>{{ $root.distribuidor.nome }}</span></span>
			<i class="pi pi-fw pi-cog"></i>
		</button>
		
        <transition name="layout-submenu-wrapper">
            <ul v-show="expanded">
                <li><button class="p-link" @click="perfil" v-if="$root.distribuidor == null"><i class="pi pi-fw pi-user"></i><span>Configurações</span></button></li>
				<li><button class="p-link" @click="conectarMP" v-if="!conectadoMP"><i class="pi pi-money-bill"></i><span>Conectar ao MP</span></button></li>
                <li><button class="p-link" @click="logout"><i class="pi pi-fw pi-power-off"></i><span>Sair</span></button></li>
            </ul>
        </transition>
		
	</div>
</template>

<script>
	// import firebase from 'firebase/app';
	// import 'firebase/auth';

	export default {
		data() {
			return {
				expanded: false,
				nome: "",
				conectadoMP: false,
			}
		},
		watch: {
			'$auth.usuario': {
				handler: function(user) {
					if(user) {
						this.nome = user.nome;
						this.conectadoMP = (user.MP != null);
					} else {
						this.nome = "";
						this.conectadoMP = false;
					}
				},
				deep: true,
				immediate: true
			},

		},
		methods: {
			onClick(event){
				this.expanded = !this.expanded;
				event.preventDefault();
			},
			async logout() {
				this.$root.distribuidor = null;
				window.localStorage.removeItem("VIPDeliveryDistribuidor");
				await this.$auth.logout();
				this.$router.replace('/login');

			},
			perfil() {
				const self = this;
				self.$router.push('/perfil');
			},
			async conectarMP() {
				
				const response = await this.$apiv1.get("/mercadopago/loja/conectar");
				const data = response.data;

				if(data.success) {
					window.location.href = data.url;
				} else {
					alert('Erro ao gerar o link de conexão! Tente novamente.');
				}

			}
		}
	}
</script>

<style scoped>

</style>