<template>
	<div class="layout-topbar">
		<div class="grid">

			<div class="col-6">

				<button class="p-link layout-menu-button" @click="onMenuToggle">
					<span class="pi pi-bars"></span>
				</button>
				<div class="layout-topbar-icons" v-if="listaLojas.length > 0">
					<Dropdown v-model="lojaSelecionada" :options="listaLojas" optionLabel="nome"
						placeholder="Selecionar loja" class="w-full md:w-14rem" />
				</div>

			</div>

			<div class="col-6 text-right">
				<strong>{{ $root.version }}</strong>
			</div>

		</div>

	</div>
</template>

<script>
export default {
	methods: {
		onMenuToggle(event) {
			this.$emit('menu-toggle', event);
		}
	},
	data() {
		return {
			lojaSelecionada: null,
			listaLojas: []
		}
	},
	async mounted() {
		console.log('amount');
		const ret = await this.$api.get('/lojas');
		//console.log(ret.data);
		this.listaLojas = ret.data.data;
		this.lojaSelecionada = this.listaLojas[0];
	},
	watch: {
		lojaSelecionada: {
			handler: function (val) {
				this.$auth.usuario.loja = this.$utils.getStdObject(val);
			},
			deep: true
		}
	}
}
</script>