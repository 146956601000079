import axios from 'axios';

import firebase from 'firebase/app';
import 'firebase/auth';

export default class {

    constructor(api) {
        this.urlApi = api;
        this.authV2 = null;
    }

    setAuthV2(auth) {
        this.authV2 = auth;
    }

    async headers() {

        let usuario;
        if(this.authV2?.usuario?.id) {
            usuario = this.authV2.usuario.id;
        } else {
            usuario = firebase.auth().currentUser.uid;
        }

        return { 
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + Buffer.from(usuario).toString('base64'),
        };

    }

    async get(endpoint/*, data = null*/) {

        if(typeof endpoint != "string")
            return null;

        //TRATAR PARAMS typeof data != object || data == null

        return axios({
            method: 'get',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
        });

    }

    async post(endpoint, data = null) {

        if(typeof endpoint != "string" || typeof data != "object" || data == null)
            return null;

        return axios({
            method: 'post',
            url: this.urlApi + endpoint,
            headers: await this.headers(),
            "data" : data
        });

    }

}