import utils from '../utils';
import axios from 'axios';
import Auth from './auth';

export default class API {

    #idEmpresa = "";
    #idEmpresaPrev = "";
    #usoUnicoEmpresa = false;

    urlApi;
    token = "";

    constructor(api) {
        this.urlApi = api;
    }

    setIdEmpresa(id = "", usoUnico = false) {
        this.#idEmpresaPrev = this.#idEmpresa;
        this.#usoUnicoEmpresa = usoUnico;
        this.#idEmpresa = utils.emptyStr(id);
    }

    setToken(token) {
        this.token = token;
    }

    setBearerToken(token) {
        this.token = 'Bearer ' + token;
    }

    headers() {

        const gToken = Auth.getToken();

        this.setBearerToken(gToken.token);

        let token = "";
        if(typeof this.token == 'string' && this.token != "") {
            token = this.token;
            this.token = "";
        }

        let headers = {
            'Accept': 'application/json'
        };

        if(token != "") {
            headers.Authorization = token;
        }

        if(this.#idEmpresa != "") {
            headers.idEmpresa = this.#idEmpresa;
            if(this.#usoUnicoEmpresa) {
                this.#idEmpresa = this.#idEmpresaPrev;
                this.#idEmpresaPrev = "";
                this.#usoUnicoEmpresa = false;
            }
        }

        return headers;

    }

    async get(endpoint, data = {}) {

        if(typeof endpoint != "string")
            return null;

        data = utils.validObject(data, {}, true);

        for(const [k,v] of Object.entries(data)) {
            if(Array.isArray(v))
                data[k] = v.join("|");
            else if(typeof v != "string") {
                delete data[k];
            }
        }

        let query = (new URLSearchParams(data)).toString();
        if(query != "")
            query = "?" + query;

        return axios({
            method: 'get',
            url: this.urlApi + endpoint + query,
            withCredentials: true,
            headers: this.headers(),
            data: null
        });

    }

    async post(endpoint, data = null) {

        if(typeof endpoint != "string")
            return null;

        if(typeof data != "object" || data == null)
            data = {};

        return axios({
            method: 'post',
            url: this.urlApi + endpoint,
            withCredentials: true,
            headers: this.headers(),
            "data" : data
        });

    }

    async put(endpoint, data = null) {

        if(typeof endpoint != "string" || typeof data != "object" || data == null)
            return null;

        if(typeof data != "object" || data == null)
            data = {};

        return axios({
            method: 'put',
            url: this.urlApi + endpoint,
            withCredentials: true,
            headers: this.headers(),
            "data" : data
        });

    }

    async delete(endpoint, data = null) {

        if(typeof endpoint != "string")
            return null;

        if(typeof data != "object" || data == null)
            data = {};

        return axios({
            method: 'delete',
            url: this.urlApi + endpoint,
            withCredentials: true,
            headers: this.headers(),
            "data" : data
        });

    }

    async cache(endpoint, forceUpdate = false, log = false) {

        // TODO REVISAR (NÃO USAR!)

        if(log) console.log("API CACHE: ", endpoint);

        let versionDatabase = "";

        if(!forceUpdate) {

            if(typeof endpoint != "string")
                return null;

            let versionLocal = "";

            const res = await this.get(endpoint + ((endpoint.includes("?"))?"&":"?") + "onlyVersion=true");
            
            if(typeof res?.headers["X-Version"] == "string")
                versionDatabase = res.headers["X-Version"];
            else if(typeof res?.headers["x-version"] == "string")
                versionDatabase = res.headers["x-version"];
            else
                console.warn("Api Cache (" + endpoint + "): Controle de Versão Indisponível para o Endpoint! O Download sempre será realizado!");

            if(versionDatabase != "") {

                const version = window.localStorage.getItem("VIPDELIVERY/" + endpoint + "/version");

                if(version)
                    versionLocal = version;

                if(versionDatabase == versionLocal) {
                    if(log) console.log("Api Cache (" + endpoint + "): Carregamento Local.");
                    const data = window.localStorage.getItem("VIPDELIVERY/" + endpoint);
                    if(typeof data == "string" && data != "")
                        return JSON.parse(data);
                    return null;
                }

            }

        }

        if(log) console.log("Api Cache (" + endpoint + "): Carregamento por Download.");

        const res = await this.get(endpoint);

        if(res?.data.success) {

            if(typeof res.headers["X-Version"] == "string")
                versionDatabase = res.headers["X-Version"];
            else if(typeof res.headers["x-version"] == "string")
                versionDatabase = res.headers["x-version"];

            if(versionDatabase != "") {
                window.localStorage.setItem("VIPDELIVERY/" + endpoint + "/version", versionDatabase);
                window.localStorage.setItem("VIPDELIVERY/" + endpoint, JSON.stringify(res.data.data));
            }
            return res.data.data;

        }

        if(log) console.log("Api Cache (" + endpoint + "): Falha na execução!");

        return null;

    }

}
