import moment from 'moment-timezone';
import Compressor from 'compressorjs';
export default class {

  static emptyStr(val) {
    if (typeof val == "undefined" || typeof val == "object" && val == null)
      return "";
    return val.toString();
  }

  static formatPhone(phone) {
    phone = phone.replace(/[^0-9]/g, "");
    return '(' + phone.substr(0, 2) + ') ' + phone.substr(2, phone.length - 6) + '-' + phone.substr(-4);
  }

  static formatCurrency(val) {
    if (typeof val == "string")
      val = parseFloat(val);
    else if (typeof val != "number")
      return "";

    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val);
  }

  static formatDate(date, format = "DD/MM/YYYY HH:mm:ss") {
    if (typeof date != "undefined" && date != null && date != "") {
        return moment(date, true).tz('America/Sao_Paulo').format(format);
    }
    return "";
  }

  static formatDateToBr(date) {
    if (typeof date != "string" || date == "")
      return "";
    return date.split("-").reverse().join("/");
  }

  static formatTimeStampToData(timeStamp, formato = "DD/MM/YYYY HH:mm:ss") {
    if (typeof timeStamp == "undefined") {
      return ""
    }
    return moment(timeStamp, 'YYYYMMDDHHmmss').tz("America/Sao_Paulo").format(formato);
  }

  static formatDateToMysql(date) {
    if (typeof date != "string" || date == "")
      return "";
    return date.split("/").reverse().join("-");
  }

  static formatDateHourToBr(date) {
    if (typeof date != "string" || date == "")
      return "";
    var dateHour = date.split(" ");
    return dateHour[0].split("-").reverse().join("/") + " " + dateHour[1];
  }

  static formatDateHourToMysql(date) {
    if (typeof date != "string" || date == "")
      return "";
    var dateHour = date.split(" ");
    return dateHour[0].split("/").reverse().join("-") + " " + dateHour[1];
  }

  static getStdObject(obj) {
    if (typeof obj == "undefined" || obj == null)
      return null;
    return JSON.parse(JSON.stringify(obj));
  }

  static isCPF(strCPF) {
    strCPF = strCPF.replace(/[^0-9]/g, "");

    var i;
    var Soma;
    var Resto;
    Soma = 0;

    if (strCPF == "00000000000") return false;

    for (i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;

    return true;
  }

  static isCNPJ(cnpj) {
    var i;

    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj == "") return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999"
    )
      return false;

    // Valida DVs
    var tamanho = cnpj.length - 2;
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;

    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }

    var resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado != digitos.charAt(0)) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

    if (resultado != digitos.charAt(1)) return false;

    return true;
  }

  static validBoolean(val, def = false) {
    if (typeof def != "boolean")
      def = false;
  
    if (typeof val == "boolean")
      return val;
  
    return def;
  }

  static validArray(val, def = []) {
    if (typeof def != "object" || !Array.isArray(def))
      def = [];
  
    if (typeof val == "object" && val != null) {
  
      if (Array.isArray(val)) {
        return val;
      }
  
      const k = Object.keys(val);
      let i;
      for (i = 0; i < k.length && k[i] == i.toString(); i++);
      if (i == k.length)
        return Object.values(val);
  
    }
  
    return def;
  }

  static validDate(val, def = null, strict = false) {
    if (strict && typeof def != "object")
      def = null;
  
    if (typeof val == "undefined" || val == null)
      return def;
  
    if (typeof val == "object" && val instanceof Date)
      return val;
  
    if (typeof val == "string" && /[0-2][0-9][0-9][0-9]-[0-1][0-9]-[0-3][0-9]T[0-2][0-9]:[0-5][0-9]:[0-5][0-9].*/.test(val))
      return new Date(val);
  
    return def;
  }

  static validObject(val, def = {}, notNull = true) {
    if (typeof def != "object")
      def = null;
  
    if (typeof val == "object")
      if (notNull == false || val != null)
        return val;
  
    return def;
  }

  static validString(val, def = "", notEmpty = false) {
    if (typeof def != "string")
      def = "";
  
    if (typeof val == "string") {
      if (notEmpty == false || val.trim() != "")
        return val;
    }
  
    return def;
  }

  static getPathOfObject(obj, path) {

    if (typeof path != "string" || path == "")
      return undefined;
  
    const _path = path.split(".");
  
    for (const p of _path) {
      if (typeof obj == "object" && obj != null)
        obj = obj[p];
      else {
        obj = undefined;
        break;
      }
    }
  
    return obj;
  
  }

   /**
   * Função de Callback da compressão de imagem:
   * @callback fnSucesso
   * @param {(File|null)} file - Arquivo comprimido ou null em caso de erro.
   * 
   * Função para comprimir as imagens.
   * @param {File} file - Arquivo a ser comprimido.
   * @param {fnSucesso} callback - Função de retorno executada após a compressão.
   * @returns void
   */
   static comprimirImagen(file, callback) {

    if(typeof file != "object" || file == null) {
      callback(null);
      return;
    }
      
    new Compressor(file, {
      quality: 0.85,
      mimeType: "image/webp",
      maxWidth: 1920,
      maxHeight: 1920,
      success(result) {

        const idx = result.name.lastIndexOf(".");
        const ext = result.name.substr(idx);
        
        result.name = moment().format("YYYYMMDDHHmmSS") + ext;

        const file = new File([result], result.name, {type: result.type});

        callback(file);

      },
      error(err) {

        console.error("[SYS] Erro ao comprimir imagem.");
        console.error(err);
        callback(null);

      }
    });

  }

}