import { io } from "socket.io-client";
import { UAParser } from 'ua-parser-js';
import Auth from "./auth";

export default class {

    socket = null;
    dispositivo = {};

    connected = false;
    #connected = false;

    constructor(url) {

        this.url = url;

        this.socket = io(url, {
            transports: ['websocket']
        });

        this.socket.on("connect", () => {
            this.#connected = true;
        });

        this.socket.on("auth-ret", (data) => {
            this.connected = (data?.success === true);
        });

        this.socket.on("disconnect", () => {
            this.connected = false;
            this.#connected = false;
        });

        setInterval(() => {
            if(this.#connected)
                this.socket.emit("ping");
        }, 300000);

    }

    #emitR(event, value = null) {

        let tentativas = 9;
        const timerR = setInterval(() => {
            if(tentativas-- == 0)
                clearInterval(timerR);
            if(this.#connected) {
                clearInterval(timerR);
                this.socket.emit(event, value);
            }
        }, 500);

    }

    async identify(id) {

        if(typeof id != "string" || id.trim() == "")
            return;

        this.dispositivo = {
            id: id,
            meta: {
                ...{ ip: await (await fetch("https://api64.ipify.org")).text() },
                ...UAParser(window.navigator.userAgent)
            }
        };

        const auth = Auth.getToken();

        if(auth.token != "") {
            this.#emitR("auth", {
                ...auth,
                ...{ dispositivo: this.dispositivo }
            });
        } else {
            this.#emitR("identify", {
                dispositivo: this.dispositivo
            });    
        }

    }

    auth(auth) {
        if(!auth?.token)
            false;

        if(!auth.dispositivo?.id)
            auth.dispositivo = this.dispositivo;

        this.#emitR("auth", auth);
    }

    logout() {
        this.#emitR("logout");
    }

    on(event, fn) {
        this.socket.on(event, fn);
    }

    off(event = false, fn = false) {

        if(!event && !fn)
            this.socket.removeAllListeners();

        if(!fn)
            this.socket.removeAllListeners(event);

        this.socket.off(event, fn);

    }

}