<template>
	<div v-if="withLayout" :class="containerClass" @click="onWrapperClick">
        <audio ref="alert" loop>
            <source src="/wav/alert.wav" type="audio/wav">
        </audio>

		<AppTopBar @menu-toggle="onMenuToggle" />

        <transition name="layout-sidebar">
            <div :class="sidebarClass" @click="onSidebarClick" v-show="isSidebarVisible()">
                <div class="layout-logo">
                    <router-link to="/">
                        <img v-if="layoutColorMode === 'dark'" alt="Logo" src="./assets/logo.png" />
                        <img v-else alt="Logo" src="./assets/logo.png" />
                    </router-link>
                </div>

                <AppProfile />

                <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />

            </div>
        </transition>

		<div class="layout-main">
			<router-view />
		</div>

		<AppFooter />
	</div>
    <div v-else><router-view /></div>
</template>

<script>
import AppTopBar from './components/AppTopbar.vue';
import AppProfile from './components/AppProfile.vue';
import AppMenu from './components/AppMenu.vue';
import AppFooter from './components/AppFooter.vue';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

export default {
    data() {
        return {
            withLayout: false,
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false, 
            version: "1.1.1 beta",
            user: null,
            distribuidor: null,
            alert: false,
            menu : [
                {label: 'Dashboard', icon: 'pi pi-fw pi-th-large', to: '/'},
                {label: 'Produtos', icon: 'pi pi-fw pi-ticket', to: '/produtos'},
                {label: 'Pedidos À Acertar', icon: 'pi pi-fw pi-dollar', to: '/pedidos-pendentes'},
                {
                        label: "Relatórios",
                        icon: "pi pi-list",
                        items: [
                            {label: 'Pedidos Finalizados', icon: 'pi pi-fw pi-check-square', to: '/pedidos'},
                            {label: 'Carrinhos Abertos', icon: 'pi pi-fw pi-shopping-cart', to: '/carrinhos'},
                        ]
                    }
            ],
        }
    },
    created() {

        const self = this;

        // TODO REVISAR!!!
        // firebase.auth().onAuthStateChanged((user) => {
        //     if(user) {

        //         firebase.database().ref("Usuarios").child(user.uid).on("value", (snapshot) => {
        //             if(snapshot.exists()) {
        //                 let u = snapshot.val();

        //                 if(typeof u.permicao == "undefined") {
        //                     self.distribuidor = null;
        //                     window.localStorage.removeItem("VIPDeliveryDistribuidor");
        //                     self.$auth.logout();
        //                 }
                        
        //                 let uu = {
        //                     id: self.$utils.getStdObject(user.uid),
        //                     permicao: self.$utils.getStdObject(u.permicao),
        //                     categoria: self.$utils.getStdObject(u.categoria),
        //                     cnpj: self.$utils.getStdObject(u.cnpj),
        //                     nome: self.$utils.getStdObject(u.nome),
        //                     cep: self.$utils.getStdObject(u.cep),
        //                     endereco: self.$utils.getStdObject(u.endereco),
        //                     complemento: self.$utils.getStdObject(u.complemento),
        //                     email: self.$utils.getStdObject(u.email),
        //                     fone: self.$utils.getStdObject(u.fone),
        //                     latitude: self.$utils.getStdObject(u.latitude),
        //                     longitude: self.$utils.getStdObject(u.longitude),
        //                     raioEntrega: self.$utils.getStdObject(u.raioEntrega),
        //                     imagen: self.$utils.getStdObject(u.imagen),
        //                     logistica: self.$utils.getStdObject(u.empresaLogistica.id),
        //                     distribuidores: self.$utils.getStdObject(u.distribuidores),
        //                     MP: null,
        //                 };

        //                 if(typeof u.mp_access_token == "string") {

        //                     uu.MP = {
        //                         "access_token" : u.mp_access_token,
        //                         "expire_timestamp" : u.mp_expire_timestamp,
        //                         "user_id" : u.mp_user_id,
        //                         "public_key" : u.mp_public_key,
        //                     };

        //                 }

        //                 const dist = window.localStorage.getItem("VIPDeliveryDistribuidor");

        //                 if(typeof dist == "string" && dist != "" && typeof uu.distribuidores[dist] == "object") {
        //                     self.distribuidor = uu.distribuidores[dist];
        //                     delete self.distribuidor.senha;
        //                 }

        //                 self.user = uu;

        //             } else {
        //                 self.distribuidor = null;
        //                 self.user = null;
        //             }
        //         });
        //     } else {
        //         self.distribuidor = null;
        //         window.localStorage.removeItem("VIPDeliveryDistribuidor");
        //         self.$auth.logout();
        //         self.user = null;
        //         self.$router.push('/login');
        //     }
        // });

        firebase.database().ref("GrerenciarVesao").child("webDeliveryAdmin").on("value", (snapshot) => {
            if(!snapshot.exists()) return;
            const version = snapshot.val();
            
            if(window.location.hostname != "localhost" && version != self.version)
                location.reload(true);
        });

    },
    watch: {
        $route() {
            this.withLayout = !(this.$route.matched.some(record => record.meta.freeLayout));
            this.menuActive = false;
            this.$toast.removeAllGroups();
        },
        user: function(u) {
            if(u != null) {

                this.menu = [
                    {label: 'Dashboard', icon: 'pi pi-fw pi-th-large', to: '/'},
                    {label: 'Pedidos À Acertar', icon: 'pi pi-fw pi-dollar', to: '/pedidos-pendentes'},
                ];

                if(this.distribuidor == null) {
                    this.menu.push({label: 'Produtos', icon: 'pi pi-fw pi-ticket', to: '/produtos'});
                    this.menu.push({label: 'Distribuidores', icon: 'pi pi-fw pi-map-marker', to: '/distribuidores'});

                    this.menu.push({
                        label: "Relatórios",
                        icon: "pi pi-list",
                        items: [
                            {label: 'Pedidos Finalizados', icon: 'pi pi-fw pi-check-square', to: '/pedidos'},
                            {label: 'Carrinhos Abertos', icon: 'pi pi-fw pi-shopping-cart', to: '/carrinhos'},
                        ]
                    });

                }

            }
        },
        alert: function(a) {
            console.log(a)
            // if(typeof this.$refs.alert == "undefined")
            //     return;
            // if(a)
            //     this.$refs.alert.play();
            // else
            //     this.$refs.alert.pause();
        }
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
		onLayoutColorChange(layoutColorMode) {
			this.layoutColorMode = layoutColorMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth > 1024;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
                else
                    return true;
            }
            else {
                return true;
            }
        },
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': 'outlined', // opção 2 // 'filled',
				'p-ripple-disabled': false,
            }];
        },
        sidebarClass() {
            return ['layout-sidebar', {
                'layout-sidebar-dark': this.layoutColorMode === 'dark',
                'layout-sidebar-light': this.layoutColorMode === 'light'
            }];
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppProfile': AppProfile,
        'AppMenu': AppMenu,
        'AppFooter': AppFooter,
    }
}
</script>

<style lang="scss">

  .layout-sidebar .layout-logo img {
      width: 139px;
      max-width: 90%;
      margin-bottom: 1em;
  }

  .p-toast.p-toast-topright {
      z-index: 1000;
      top: 70px;
  }

  .col-actions {
      width: 120px;
      text-align: center !important;
  }

  .p-autocomplete-multiple .p-inputtext {
      border-color: #0086eb; 
  }


</style>
