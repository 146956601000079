import utils from "../utils";
import axios from 'axios';
import Auth from './auth';

export default class {

    #idEmpresa = "";
    #idEmpresaPrev = "";
    #usoUnico = false;

    urlApi;
    token = "";

    constructor(api) {
        this.urlApi = api;
    }

    setIdEmpresa(id = "", usoUnico = false) {
        this.#idEmpresaPrev = this.#idEmpresa;
        this.#usoUnico = usoUnico;
        this.#idEmpresa = utils.emptyStr(id);
    }

    setToken(token) {
        this.token = token;
    }

    setBearerToken(token) {
        this.token = 'Bearer ' + token;
    }

    headers() {

        const gToken = Auth.getToken();

        this.setBearerToken(gToken.token);

        let token = "";
        if(typeof this.token == 'string' && this.token != "") {
            token = this.token;
            this.token = "";
        }

        let headers = {
            'Accept': 'application/json'
        };

        if(token != "") {
            headers.Authorization = token;
        }

        if(this.#idEmpresa != "") {
            headers.idEmpresa = this.#idEmpresa;
            if(this.#usoUnico) {
                this.#idEmpresa = this.#idEmpresaPrev;
                this.#idEmpresaPrev = "";
                this.#usoUnico = false;
            }
        }

        return headers;

    }

    async upload(files, options) {

        if(typeof options != "object" || options == null)
            options = {};

        let erro = !(typeof files == "object" && files != null);

        if(!erro) {

            if(Array.isArray(files)) {

                if(files.length == 0)
                    erro = true;

            } else {

                files = [files];

            }

        }

        if(erro) {
            return {
                success: false,
                message: "Nenhum arquivo selecionado!",
                error: {
                    code: "UPLOAD_FILE_MISSING",
                    message: "Nenhum arquivo informado para enviar.",
                    original: null
                }
            };
        }

        const options_default = {
            onProgress: () => {},
            arquivoNome: "",
            diretorio: "",
            modelo: "",
            modeloId: ""
        };

        options = {...options_default, ...options};

        const headers = this.headers();

        headers["Content-Type"] = files[0].type;

        if(typeof options.arquivoNome != "string" || options.arquivoNome.trim() == "") {
            headers["X-File-Name"] = files[0].name.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
        } else {
            headers["X-File-Name"] = options.arquivoNome;
        }

        if(typeof options.diretorio == "string" && options.diretorio.trim() != "") {
            headers['x-dir-name'] = options.diretorio;
        }

        if(typeof options.modelo == "string" && options.modelo.trim() != "") {
            headers['x-modelo'] = options.modelo;
        }

        if(typeof options.modeloId != "undefined" && options.modeloId != null && options.modeloId.toString() != "") {
            headers['x-modelo-id'] = options.modeloId.toString();
        }

        try {

            const response = await axios({
                method: "post",
                url: this.urlApi,
                data: files[0],
                headers: headers,
                onUploadProgress: data => {
                    let progress = data.progress;
                    if(data.progress === undefined || data.progress == null)
                        progress = parseFloat(((100 * data.loaded) / data.total).toFixed(2));
                    else
                        progress = parseFloat((100*progress).toFixed(2));
                    options.onProgress(progress);
                }
            });

            return response.data;

        } catch (error) {
            
            console.error(error);

            return {
                success: false,
                message: "Falha ao enviar o arquivo!",
                error: {
                    code: "UPLOAD_GENERIC_ERROR",
                    message: "Falha ao enviar o arquivo.",
                    original: error
                }
            };

        }

    }

}