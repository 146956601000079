import { reactive } from 'vue';
import utils from '../utils';

export default class Auth {

    #api;
    #rt;
    #usuario;
    #empresa;
    #loja;
    #usuarioFuncao;
    #expire;

    constructor(api, rt = null) {

        api = utils.validObject(api, null, false);

        if(api == null)
            throw "[AUTH] API Inválida!";

        this.#rt = (rt)?rt:null;

        this.#api = api;

        this.clearUsuario();

    }

    get usuario() {
        return this.#usuario;
    }

    get empresa() {
        return this.#empresa;
    }

    get loja() {
        return this.#loja;
    }

    get funcao() {
        return this.#usuarioFuncao;
    }

    get expire() {
        return this.#expire;
    }

    existsUsuario() {
        return (typeof this.#usuario.id == "string" && this.#usuario.id != "");
    }

    isLogged() {
        return this.existsUsuario();
    }

    clearUsuario() {
        this.#usuario = reactive({});
        this.#empresa = reactive({});
        this.#loja = reactive({});
        this.#usuarioFuncao = reactive({});
    }

    async loadUsuario(id = "") {

        if(typeof id != "string" || id.trim() == "") {
            if(typeof this.#usuario.id == "string") {
                id = this.#usuario.id;
            }
        }

        if(id == "") {
            const message = "O ID do Usuário é inválido!";
            console.error("[AUTH] " + message);
            return {
                success: false,
                message: message
            }
        }

        try {

            const res = await this.#api.get("/usuarios/" + id + '?fields=short,funcao,loja&with=funcao,empresa,loja');
            if (res?.data.success) {
                
                this.#usuario = reactive(res.data.data);
                this.#empresa = reactive(res.data.data.empresa);
                this.#loja = reactive(res.data.data.loja);
                this.#usuarioFuncao = reactive(res.data.data.funcao);

                return {
                    success: true,
                    message: "Informações do Usuário foram carregadas com sucesso!"
                }

            } else {

                const message = "O Usuário não existe no banco de dados! Contate o Suporte.";
                console.error("[AUTH] " + message);
                return {
                    success: false,
                    message: message
                }

            }

        } catch (e) {

            const message = "Erro ao carregar as informações do Usuário via API!";
            console.error("[AUTH] " + message);

            return {
                success: false,
                message: message
            }

        }

    }

    userCan(idPermissao) {

        // TODO VALIDAÇÃO ONLINE NO ENDPOINT CORRESPONDENTE

        if(typeof idPermissao != "string" || idPermissao.trim() == "" || this.existsUsuario() == false)
            return false;

        let permissoes = utils.validArray(this.#usuarioFuncao?.permissoes);

        if(permissoes.length == 0)
            return false;

        if(permissoes.includes("*"))
            return true;

        const regex = idPermissao.indexOf("*") != -1;
    
        let condition;
        if(regex) {
            condition = new RegExp(idPermissao);
        }

        return (permissoes.filter((el) => {
            if(regex)
                return condition.test(el);
            else
                return el == idPermissao;
        })).length > 0;

    }

    static getToken() {

        // XXX POG LOGIN

        let data = window.localStorage.getItem("VIPDELIVERY/LOGIN");
        if(typeof data == "string" && data != "") {
            data = JSON.parse(data);
        } else {
            return {
                success: false,
                message: "Sessão não identificada!",
                token: "",
                idEmpresa: ""
            };
        }

        const expire = utils.validDate(data?.expire);
        const date = new Date();
        if(!expire || expire >= date) {

            const token = data?.token ?? "";

            if(!token) {
                window.localStorage.setItem("VIPDELIVERY/LOGIN", "");
                return {
                    success: false,
                    message: "Sessão não identificada!",
                    token: "",
                    idEmpresa: ""
                };
            }

            return {
                success: true,
                token: token,
                idEmpresa: data?.empresa?.id ?? ""
            };
        }

        window.localStorage.setItem("VIPDELIVERY/LOGIN", "");
        return {
            success: false,
            message: "Token Expirado!",
            token: "",
            idEmpresa: data?.empresa?.id ?? ""
        };

    }

    /// API ///

    async login(email, senha) {

        try {

            const res = await this.#api.post("/auth/login", {
                "email": email,
                "senha": senha
            });
            
            // XXX POG LOGIN
            window.localStorage.setItem("VIPDELIVERY/LOGIN", JSON.stringify(res?.data.data));

            this.#expire = utils.validDate(res?.data.data.expire);

            this.#usuario = reactive(res?.data.data);
            this.#empresa = reactive(res?.data.data.empresa);
            this.#loja = reactive(res?.data.data.loja ?? {});
            this.#usuarioFuncao = reactive(res?.data.data.funcao);

            this.#rt?.auth({
                token: res?.data.data.token,
                idEmpresa: res?.data.data.empresa?.id ?? ""
            })

            return {
                success: true,
                message: "Usuário autenticado com Sucesso!"
            }

        } catch (e) {

            // XXX POG LOGIN
            window.localStorage.setItem("VIPDELIVERY/LOGIN", "");
    
            this.#expire = undefined;

            this.clearUsuario(); 

            const message = utils.validString(utils.getPathOfObject(e, "response.data.message"), "Erro ao autenticar Usuário via API!", true);
            console.error("[AUTH] " + message, e);

            return {
                success: false,
                message: message
            }

        }

    }

    async logout(force = false) {

        try {

            const gToken = Auth.getToken();

            if(!gToken.success) {
                return {
                    success: true,
                    message: "Usuário desconectado com Sucesso!"
                };
            }

            if(force || (await this.check()).success) {
                await this.#api.post("/auth/logout");
            }

            // XXX POG LOGIN
            window.localStorage.setItem("VIPDELIVERY/LOGIN", "");
    
            this.#expire = undefined;

            this.clearUsuario(); 

            this.#rt?.logout();

            return {
                success: true,
                message: "Usuário desconectado com Sucesso!"
            }

        } catch (e) {

            // XXX POG LOGIN
            window.localStorage.setItem("VIPDELIVERY/LOGIN", "");
    
            this.#expire = undefined;

            this.clearUsuario(); 

            const message = utils.validString(utils.getPathOfObject(e, "response.data.message"), "Erro ao desconectar Usuário!", true);

            if(e?.status != 401) {
                console.error("[AUTH] " + message, e);
            }

            return {
                success: false,
                message: message
            }

        }

    }

    async check() {

        try {

            const gToken = Auth.getToken();

            if(!gToken.success)
                throw gToken.message;

            const res = await this.#api.get("/auth/check");

            let data = {};
            if(res?.data.data) {
                data = res?.data.data;
                data.token = gToken.token;
            }

            window.localStorage.setItem("VIPDELIVERY/LOGIN", JSON.stringify(data));

            this.#usuario = reactive(data);
            this.#empresa = reactive(data.empresa ?? {});
            this.#loja = reactive(data.loja ?? {});
            this.#usuarioFuncao = reactive(data.funcao ?? {});

            this.#rt?.auth({
                token: data.token,
                idEmpresa: data.empresa?.id ?? ""
            })

            return {
                success: true,
                message: "Autenticação de Usuário verificada com Sucesso!"
            }

        } catch (e) {

            const message = utils.validString(utils.getPathOfObject(e, "response.data.message"), "Erro ao verificar a Autenticação do Usuário!", true);
            console.error("[AUTH] " + message, e);

            this.logout(true);

            return {
                success: false,
                message: message
            }

        }

    }

}
